<template>
  <b-card title="Incidències en curs">
    <incidences-mobile-list
      v-if="isMobile"
      :incidences="parsedActiveIncidences"
      :loading="loading"
      show-search
      show-button
    />
    <incidences-table
      v-else
      :items="parsedActiveIncidences"
      :fields="fields"
      :loading="loading"
      sort-field="createdAt"
      sort-order="asc"
      show-search
      show-button
    />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import IncidencesTable from "@/views/incidences/list/components/IncidencesTable.vue";
import IncidencesMobileList from "@/views/incidences/list/components/IncidencesMobileList.vue";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  components: {
    BCard,
    IncidencesTable,
    IncidencesMobileList,
  },
  data() {
    return {
      fields: [
        {
          key: "createdAt",
          label: this.$t("Data opertura"),
          sortable: true,
          thStyle: { width: "170px" },
        },
        { key: "status", label: this.$t("Estat"), sortable: true },
        { key: "bookingLocalizator", label: this.$t("Reserva") },
        { key: "accommodation", label: this.$t("Estada") },
        { key: "title", label: this.$t("Incidència") },
        { key: "lastComment", label: this.$t("Darrer comentari") },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters["app/isMobile"];
    },
    loading() {
      return this.$store.getters["incidences/loading"];
    },
    activeIncidences() {
      return this.$store.getters["incidences/activeIncidences"];
    },
    parsedActiveIncidences() {
      if (!this.activeIncidences.length) return [];
      return this.activeIncidences
        .map((incidence) => ({
          uuid: incidence.uuid || this.$t("No definit"),
          bookingUuid: incidence.booking?.uuid || null,
          bookingLocalizator:
            incidence.booking?.localizator || this.$t("No definit"),
          bookingSource: incidence.booking?.source || null,
          bookingHighlighted: incidence.booking?.highlighted || false,
          accommodation:
            incidence.booking?.accommodation?.name || this.$t("No definit"),
          checkin:
            formatDateStringToDate(incidence.booking?.checkin) ||
            this.$t("No definit"),
          checkout:
            formatDateStringToDate(incidence.booking?.checkout) ||
            this.$t("No definit"),
          title: incidence.title || this.$t("No definit"),
          status: incidence.status || this.$t("No definit"),
          createdAt: incidence.createdAt,
          lastComment: incidence.lastComment || null,
        }))
        .sort((a, b) => {
          if (a.createdAtOrder < b.createdAtOrder) return 1;
          return b.createdAtOrder < a.createdAtOrder ? -1 : 0;
        });
    },
  },
};
</script>
