<template>
  <div class="incidences-table">
    <!-- BUTTON AND SEARCH -->
    <b-row
      v-if="showSearch || showButton"
      class="mb-2 d-flex flex-wrap"
      :class="{
        'justify-content-between': showButton,
        'justify-content-end': !showButton,
      }"
    >
      <b-col
        v-if="showButton"
        cols="12"
        md="auto"
        class="mb-1 mb-md-0 order-2 order-md-1"
      >
        <b-button variant="primary" block @click.prevent="onNewButtonClicked">
          {{ $t("Nova incidència") }}
        </b-button>
      </b-col>

      <b-col
        v-if="showSearch"
        cols="12"
        md="auto"
        class="mb-1 mb-md-0 order-1 order-md-2"
        :class="{
          'justify-content-between': showButton,
          'justify-content-end': !showButton,
        }"
      >
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="loading"
        />
      </b-col>
    </b-row>

    <!-- TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          hover
          responsive
          class="position-relative"
          :busy="loading"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="items"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
          @row-clicked="onRowClicked"
          @row-contextmenu="onRowRightClicked"
          @row-middle-clicked="onRowMiddleClicked"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: bookingLocalizator -->
          <template #cell(bookingLocalizator)="data">
            <b-booking-localizator
              :localizator="data.item.bookingLocalizator"
              :source="data.item.bookingSource"
              :highlighted="data.item.bookingHighlighted"
              :fictitious="data.item.bookingFictitious"
            />
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge
              :variant="getBookingIncidenceStatusColor(data.item.status)"
            >
              {{
                getBookingIncidenceStatusName(data.item.status, $i18n.locale)
              }}
            </b-badge>
          </template>

          <!-- Column: accommodation -->
          <template #cell(accommodation)="data">
            <div class="text-nowrap mb-25">
              {{ data.item.accommodation }}
            </div>
            <small class="d-flex align-items-center flex-nowrap">
              {{ data.item.checkin }}
              <feather-icon icon="ArrowRightIcon" size="10" class="mx-25" />
              {{ data.item.checkout }}
            </small>
          </template>

          <!-- Column: checkinOrder -->
          <template #cell(checkinOrder)="data">
            {{ data.item.checkin }}
          </template>

          <!-- Column: checkoutOrder -->
          <template #cell(checkoutOrder)="data">
            {{ data.item.checkout }}
          </template>

          <!-- Column: createdAt -->
          <template #cell(createdAt)="data">
            {{ formatDateTime(data.item.createdAt) }}
          </template>

          <!-- Column: updatedAt -->
          <template #cell(updatedAt)="data">
            {{ formatDateTime(data.item.updatedAt) }}
          </template>

          <!-- Column: lastComment -->
          <template #cell(lastComment)="data">
            <small v-if="isLastCommentSolution(data.item.lastComment)">
              <b-badge class="mb-25"> Solució </b-badge>
            </small>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="getLastCommentContent(data.item.lastComment)" />
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!loading"
      v-if="items.length > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BSpinner,
  BBadge,
  BButton,
} from "bootstrap-vue";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import {
  getBookingIncidenceStatusColor,
  getBookingIncidenceStatusName,
  removeHtmlTags,
} from "@/utils/methods";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BSpinner,
    BBookingLocalizator,
    BBadge,
    BButton,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
    showButton: {
      type: Boolean,
      default: () => false,
    },
    sortField: {
      type: String,
      default: () => "checkinOrder",
    },
    sortOrder: {
      type: String,
      default: () => "asc",
    },
  },
  data() {
    return {
      isBusy: false,
      sortBy: "checkinOrder",
      sortDesc: false,
      perPage: 10,
      pageOptions: [5, 10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      selectedItem: null,
      getBookingIncidenceStatusColor,
      getBookingIncidenceStatusName,
      removeHtmlTags,
    };
  },
  watch: {
    items(items) {
      this.totalRows = items.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.sortBy = this.sortField;
    this.sortDesc = this.sortOrder === "desc";
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowClicked(item) {
      this.$router.push({
        name: "foravila-incidence-view",
        params: { incidenceUuid: item.uuid },
      });
    },
    onRowRightClicked(item) {
      this.selectedItem = item;
      this.openInNewTab();
    },
    onRowMiddleClicked(item) {
      this.selectedItem = item;
      this.openInNewTab();
    },
    openInNewTab() {
      const routeData = this.$router.resolve({
        name: "foravila-incidence-view",
        params: { incidenceUuid: this.selectedItem.uuid },
      });
      window.open(routeData.href, "_blank");
    },
    getLastCommentContent(lastComment) {
      return removeHtmlTags(lastComment?.content) || null;
    },
    isLastCommentSolution(lastComment) {
      return lastComment?.isSolution || false;
    },
    formatDateTime(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return (
        formatDateStringToDate(date, this.$i18n.locale, formatting) ||
        this.$t("No disponible")
      );
    },
    onNewButtonClicked() {
      this.$router.push({
        name: "foravila-new-incidence",
      });
    },
  },
};
</script>

<style lang="scss">
.incidences-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
}
</style>
