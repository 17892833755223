<template>
  <b-tabs no-fade>
    <b-tab active>
      <template #title>
        <feather-icon class="mb-25 mb-lg-none" icon="PlayCircleIcon" />
        <span>{{ $t("Obertes") }}</span>
      </template>
      <active-incidences-list @new-incidence="onNewIncidence" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon class="mb-25 mb-lg-none" icon="StopCircleIcon" />
        <span>{{ $t("Tancades") }}</span>
      </template>
      <closed-incidences-list @new-incidence="onNewIncidence" />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import ActiveIncidencesList from "@/views/incidences/list/components/ActiveIncidencesList.vue";
import ClosedIncidencesList from "@/views/incidences/list/components/ClosedIncidencesList.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BTabs,
    BTab,
    ActiveIncidencesList,
    ClosedIncidencesList,
  },
  created() {
    this.fetchIncidences();
  },
  methods: {
    fetchIncidences() {
      this.$store.dispatch("incidences/fetchIncidences").catch(() => {
        notifyError(
          this.$t("errors.fetchIncidences.title"),
          this.$t("errors.fetchIncidences.description")
        );
      });
    },
    onNewIncidence() {
      console.log("New incidence");
    },
  },
};
</script>
